<template>
  <div>
    <div class="row lists-page">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('MENU.SMARTLISTS')">
          <template v-slot:toolbar>
            <b-button
              variant="primary"
              class="mr-4"
              @click="openCreateModal()"
              v-if="canAdd"
            >
              {{ $t("LISTS.NEW_LIST") }}
            </b-button>
            <b-button
              variant="danger"
              v-b-modal.confirm-1
              v-if="canRemove"
              :disabled="listTable.selected.length == 0"
            >
              {{ $t("LISTS.DEL_LIST") }}
            </b-button>
          </template>
          <template v-slot:body>
            <div class="row table-filter">
              <div class="col-sm-6">
                <b-form-group
                  :label="$t('COMMON.SEL_DOMAIN')"
                  label-for="select-domain"
                >
                  <model-list-select :list="selectDomain.allDomains"
                    v-model="selectDomain.selectedDomain"
                    option-value="domain"
                    option-text="domain"
                    :placeholder="$t('COMMON.SEL_DOMAIN')"
                    @input="onChangeDomain"
                    id="select-domain">
                  </model-list-select>
                </b-form-group>
              </div>
              <div class="col-sm-6">
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  :label="$t('COMMON.SEARCH')"
                  class="cus-searchBox"
                  single-line
                  hide-details
                ></v-text-field>
              </div>
            </div>
            <v-data-table
              v-model="listTable.selected"
              :headers="headers"
              :items="listTable.lists"
              :single-select="listTable.singleSelect"
              :search="search"
              show-select
              item-key="id"
              class="elevation-1"
              ref="listTable"
              :footer-props="{
                showFirstLastPage: true,
              }"
            >
              <template v-slot:item.type="{ item }">
                <span v-if="item.type == 1">
                  {{ $t("SMARTLISTS.MEMBERS_LIST") }}
                </span>
                <span v-else>
                  {{ $t("SMARTLISTS.ALL_DOMAIN_USERS") }}
                </span>
              </template>
              <template v-slot:item.action="{ item }">
                <v-icon
                  medium
                  color="dark"
                  class="mr-2"
                  @click="showMembers(item)"
                  v-b-tooltip.hover
                  :title="$t('SMARTLISTS.MEMBERS')"
                >
                  accessibility
                </v-icon>
                <v-icon
                  medium
                  color="dark"
                  class="mr-2"
                  @click="showItemInfor(item)"
                  v-b-tooltip.hover
                  v-if="canAdd"
                  :title="$t('COMMON.EDIT')"
                >
                  edit
                </v-icon>
                <v-icon
                  medium
                  color="dark"
                  @click="saveDeltem(item)"
                  v-b-modal.confirm-2
                  v-b-tooltip.hover
                  v-if="canRemove"
                  :title="$t('COMMON.DELETE')"
                >
                  delete
                </v-icon>
              </template>
            </v-data-table>
            <!-- ----------add edit list modal------------- -->
            <b-modal
              id="addList"
              ref="addList"
              :title="isEdit == false ? $t('SMARTLISTS.ADD_LIST') : editTitle"
              size="lg"
            >
              <b-container fluid>
                <b-row class="my-1">
                  <b-col sm="4">
                    <label
                      >{{ $t("LISTS.LIST_NAME") }} @
                      {{ $t("COMMON.DOMAIN") }}</label
                    >
                  </b-col>
                  <b-col sm="8">
                    <b-row>
                      <b-col sm="4" class="groupFormUser">
                        <b-form-input
                          id="listName"
                          v-model="$v.addForm.listName.$model"
                          :state="validateState('listName')"
                          :placeholder="$t('LISTS.LIST_NAME')"
                          aria-describedby="input-listName-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-listName-feedback">
                          {{ $t("VALIDATION.REQUIRED_FIELD") }}
                        </b-form-invalid-feedback>
                      </b-col>
                      <b-col sm="8" class="d-flex align-items-center">
                        @{{ selectDomain.selectedDomain.domain }}
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="my-4">
                  <b-col sm="4">
                    <label class="mb-0">{{ $t("SMARTLISTS.DG_TYPE") }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-select
                      v-model="addForm.dgtype.selected"
                      value-field="value"
                      text-field="name"
                      :options="dgtypeData"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-4">
                  <b-col sm="4">
                    <label class="mb-0">{{
                      $t("SMARTLISTS.LIST_PROTECTION")
                    }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-select
                      v-model="addForm.dg_protection.selected"
                      value-field="value"
                      text-field="name"
                      :options="dg_protectionData"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-4" v-if="addForm.dg_protection.selected == 2">
                  <b-col sm="4">
                    <label class="mb-0">{{ $t("COMMON.PASSWORD") }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-input
                      v-model="$v.addForm.password.$model"
                      :state="validateState('password')"
                      :placeholder="$t('COMMON.PASSWORD')"
                      aria-describedby="input-password-feedback"
                    ></b-form-input>
                    <span>{{ $t("SMARTLISTS.PASSWORD_TXT") }}</span> <br />
                    <span>
                      {{ $t("SMARTLISTS.EX") }}: [{{ addForm.password ? addForm.password : $t("SMARTLISTS.MY_SUBJECT") }}]
                    </span>
                    <b-form-invalid-feedback id="input-password-feedback">
                      {{ $t("VALIDATION.REQUIRED_FIELD") }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row
                  class="my-4"
                  v-if="
                    addForm.dgtype.selected == 2 &&
                    addForm.dg_protection.selected == 1
                  "
                >
                  <b-col sm="4">
                    <label class="mb-0">{{
                      $t("SMARTLISTS.ALL_MEMBER_POST")
                    }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-select
                      v-model="addForm.all_members_can_post.selected"
                      value-field="value"
                      text-field="text"
                      :options="allMembersPostData"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-4 p-l-10">
                  <b-form-checkbox
                    v-model="addForm.send_to_sender"
                    value="1"
                    unchecked-value="0"
                  >
                    {{ $t("SMARTLISTS.SEND_LABEL") }}
                  </b-form-checkbox>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="modalClose('addList')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button
                  variant="primary"
                  v-on:click="createItem()"
                  v-if="!isEdit"
                >
                  {{ $t("SMARTLISTS.ADD_LIST") }}
                </b-button>
                <b-button
                  variant="primary"
                  v-on:click="updateItem()"
                  v-if="isEdit"
                >
                  {{ $t("SMARTLISTS.UPDATE_LIST") }}
                </b-button>
              </template>
            </b-modal>

            <!-- ----------manage member modal start------------- -->
            <b-modal
              hide-footer
              id="memberManage"
              ref="memberManage"
              size="lg"
              :title="memberManage.memberTitle"
            >
              <b-container fluid>
                <b-row class="my-4" v-if="canAdd">
                  <b-col sm="8">
                    <b-row>
                      <b-col sm="5">
                        {{ $t("SMARTLISTS.CAN_POST") }}
                      </b-col>
                      <b-col sm="7">
                        <b-form-select
                          v-model="memberManage.can_post.selected"
                          value-field="value"
                          text-field="text"
                          :options="can_postData"
                        ></b-form-select>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="my-4" v-if="canAdd">
                  <b-col sm="8">
                    <b-row>
                      <b-col sm="5">
                        {{ $t("SMARTLISTS.CAN_RECEIVE") }}
                      </b-col>
                      <b-col sm="7">
                        <b-form-select
                          v-model="memberManage.can_receive.selected"
                          value-field="value"
                          text-field="text"
                          :options="can_receiveData"
                          :state="canReceiveValidator()"
                        ></b-form-select>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="my-4" v-if="canAdd">
                  <b-col sm="5">
                    <b-form-select
                      v-model="memberManage.memberMode.selected"
                      value-field="value"
                      text-field="text"
                      :options="
                        editData.type == 1
                          ? memberModeData
                          : memberModeDataInternal
                      "
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-4" v-if="canAdd">
                  <b-col sm="5" v-if="memberManage.memberMode.selected">
                    <b-form-input
                      v-model="$v.addForm.memberName.$model"
                      :state="validateState('memberName')"
                      :placeholder="$t('LISTS.EMAIL_ADDRESS_DOMAIN')"
                      aria-describedby="input-memberName-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-memberName-feedback">
                      {{ $t("VALIDATION.EMAIL_DOMAIN_FIELD") }}
                    </b-form-invalid-feedback>
                  </b-col>
                  <b-col sm="5" v-else>
                    <b-form-select
                      v-model="memberManage.memberEmail.selected"
                      value-field="value"
                      text-field="text"
                      :options="memberManage.memberEmail.data"
                    ></b-form-select>
                  </b-col>
                  <b-col sm="4">
                    <b-button variant="primary" @click="addMember()">
                      {{ $t("LISTS.ADD_MEMBER") }}
                    </b-button>
                  </b-col>
                  <b-col sm="3">
                    <b-button
                      variant="danger"
                      v-b-modal.delMembersConfirm-modal
                      v-if="canRemove"
                      :disabled="memberManage.selected.length == 0"
                    >
                      {{ $t("COMMON.DELETE") }}
                    </b-button>
                  </b-col>
                </b-row>
                <v-data-table
                  v-model="memberManage.selected"
                  :headers="memberTableHeaders"
                  :items="memberManage.members"
                  :single-select="memberManage.singleSelect"
                  show-select
                  item-key="id"
                  class="elevation-1"
                  ref="memberTable"
                  :footer-props="{
                    showFirstLastPage: true,
                  }"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      medium
                      color="dark"
                      @click="saveDelOneMember(item)"
                      v-b-modal.delMemberOneConfirm-modal
                      v-b-tooltip.hover
                      v-if="item.editable == 1 && canRemove"
                      :title="$t('COMMON.DELETE')"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </b-container>
            </b-modal>
            <!-- ----------manage member modal end------------- -->

            <!-- ----------delete confirm modal------------- -->
            <!-- ----------- delete one list confirm modal -->
            <b-modal
              id="confirm-2"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_ITEM_WARNING", { item: delItem.name }) }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteOneItem">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------- delete lists confirm modal -->
            <b-modal
              id="confirm-1"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_WARNING") }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteItems">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------- delete one member confirm modal -->
            <b-modal
              id="delMemberOneConfirm-modal"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delMemberConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_WARNING") }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delMemberConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteOneMember">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------- delete members confirm modal -->
            <b-modal
              id="delMembersConfirm-modal"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delMemberConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_WARNING") }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delMemberConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteMembers">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------delete confirm modal end------------- -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { validationMixin } from "vuelidate";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";
import { required, email, or, helpers } from "vuelidate/lib/validators";

const domainRegex = helpers.regex(
  "domain", 
  /^[a-z0-9+-]+(\.[a-z0-9+-]+)*(\.[a-z]{2,11})$/
);

export default {
  mixins: [validationMixin],
  name: "smartlists",
  components: {
    KTPortlet,
  },
  data: () => ({
    search: "",
    listTable: {
      singleSelect: false,
      lists: [],
      selected: [],
    },
    selectDomain: {
      selectedDomain: {},
      allDomains: [],
    },
    addForm: {
      dgtype: {
        selected: 1,
      },
      dg_protection: {
        selected: 1,
      },
      all_members_can_post: {
        selected: 1,
      },
      listName: "",
      password: "",
      send_to_sender: "0",
      memberName: "",
    },
    delItem: "",
    editData: "",
    isEdit: false,
    editTitle: "",
    memberManage: {
      memberTitle: "",
      can_post: {
        selected: 0,
      },
      can_receive: {
        selected: 1,
      },
      memberMode: {
        selected: 0,
      },
      memberEmail: {
        selected: "",
        data: [],
      },
      singleSelect: false,
      members: [],
      selected: [],
      delMemberId: "",
      delMemberName: "",
    },
  }),
  computed: {
    headers: function () {
      return [
        { text: this.$t("LISTS.LIST_NAME"), value: "name" },
        { text: this.$t("COMMON.TYPE"), value: "type" },
        { text: this.$t("COMMON.ACTION"), value: "action", sortable: false },
      ];
    },
    dgtypeData: function () {
      return [
        { value: 1, name: this.$t("SMARTLISTS.SPECIFY_MEMBERS") },
        { value: 2, name: this.$t("SMARTLISTS.ALL_DOMAIN_USERS") },
      ];
    },
    dg_protectionData: function () {
      return [
        { value: 1, name: this.$t("SMARTLISTS.ONLY_MEMBERS_POST") },
        { value: 2, name: this.$t("SMARTLISTS.PASSWORD_PROTECTED") },
        { value: 3, name: this.$t("SMARTLISTS.NO_PROTECTION") },
      ];
    },
    allMembersPostData: function () {
      return [
        { value: 0, text: this.$t("COMMON.NO") },
        { value: 1, text: this.$t("COMMON.YES") },
      ];
    },
    can_postData: function () {
      return [
        { value: 0, text: this.$t("COMMON.NO") },
        { value: 1, text: this.$t("COMMON.YES") },
      ];
    },
    can_receiveData: function () {
      return [
        { value: 0, text: this.$t("COMMON.NO") },
        { value: 1, text: this.$t("COMMON.YES") },
      ];
    },
    memberModeData: function () {
      return [
        { value: 0, text: this.$t("LISTS.INTERNAL_MEMBERS") },
        { value: 1, text: this.$t("LISTS.EXTERNAL_MEMBERS") },
      ];
    },
    memberModeDataInternal: function () {
      return [{ value: 0, text: this.$t("LISTS.INTERNAL_MEMBERS") }];
    },
    memberTableHeaders: function () {
      return [
        { text: this.$t("LISTS.MEMBER"), value: "member" },
        { text: this.$t("SMARTLISTS.CAN_POST"), value: "can_post" },
        { text: this.$t("SMARTLISTS.CAN_RECEIVE"), value: "will_receive" },
        { text: this.$t("COMMON.ACTION"), value: "action", sortable: false },
      ];
    },
    canAdd: function () {
      return this.hasPermission("dglists", 5);
    },
    canRemove: function () {
      return this.hasPermission("dglists", 7);
    },
  },
  created() {
    postRequest({
      action: "getAllDomains",
      token: localStorage.id_token,
    }).then((res) => {
      if (res) {
        if (!res.returncode) {
          res.returndata = [{ domain: res.returndata }];
        }
        this.selectDomain.allDomains = res.returndata;
        this.selectDomain.selectedDomain = res.returndata[0];
        this.getListsReqest(this.selectDomain.selectedDomain.domain);
      }
    });
  },
  validations: {
    addForm: {
      listName: {
        required,
      },
      password: {
        required,
      },
      memberName: {
        required,
        isValid: or(email, domainRegex),
      },
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.SMARTLISTS") },
    ]);
  },
  methods: {
    /* -----validation---- */
    validateState(name) {
      const { $dirty, $error } = this.$v.addForm[name];
      return $dirty ? !$error : null;
    },
    canReceiveValidator() {
      let reg = new RegExp(/^[a-z0-9+-]+(\.[a-z0-9+-]+)*(\.[a-z]{2,11})$/, 'i'); // eslint-disable-line
      let isDomain = reg.test(this.addForm.memberName);
      let canReceiveSelected = this.memberManage.can_receive.selected;
      if (isDomain && canReceiveSelected == 1) {
        return false;
      }
      return true;
      // should return Boolean
    },
    /*  -----modal close------- */
    modalClose(ref) {
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.$refs[ref].hide();
      this.resetForm();
    },
    confirmModalClose(modalItem) {
      this.$refs[modalItem].hide();
    },

    /* ------ reset add form ------- */
    resetForm() {
      this.addForm.listName = "";
      this.addForm.dgtype.selected = 1;
      this.addForm.dg_protection.selected = 1;
      this.addForm.all_members_can_post.selected = 1;
      this.addForm.send_to_sender = "0";
      this.addForm.password = "";
    },

    /* ---------read item---------- */
    getListsReqest(domain) {
      this.listTable.lists = [];
      this.listTable.selected = [];
      postRequest({
        action: "getDGLists",
        token: localStorage.id_token,
        domain: domain,
      }).then((res) => {
        if (res.returncode) {
          this.listTable.lists = res.returndata;
        } else {
          this.listTable.lists = [];
        }
      });
    },
    getListsForSelectedDomain(selectedDomain) {
      this.getListsReqest(selectedDomain);
    },

    /* ------create item---- */
    openCreateModal() {
      this.isEdit = false;
      this.$refs["addList"].show();
    },
    createItem() {
      this.$v.addForm.$touch();
      if (this.$v.addForm.listName.$anyError) {
        return;
      }
      if (this.addForm.dg_protection.selected == 2) {
        if (this.$v.addForm.password.$anyError) {
          return;
        }
      }
      const selectedDomainForAdd = this.selectDomain.selectedDomain.domain;
      let request_config = {};
      if (this.addForm.dg_protection.selected == 2) {
        request_config = {
          action: "addDGList",
          token: localStorage.id_token,
          domain: selectedDomainForAdd,
          list: this.addForm.listName,
          dgtype: this.addForm.dgtype.selected,
          dg_protection: this.addForm.dg_protection.selected,
          password: this.addForm.password,
          send_to_sender: this.addForm.send_to_sender,
        };
      } else if (
        this.addForm.dg_protection.selected == 1 &&
        this.addForm.dgtype.selected == 2
      ) {
        request_config = {
          action: "addDGList",
          token: localStorage.id_token,
          domain: selectedDomainForAdd,
          list: this.addForm.listName,
          dgtype: this.addForm.dgtype.selected,
          send_to_sender: this.addForm.send_to_sender,
          dg_protection: this.addForm.dg_protection.selected,
          all_members_can_post: this.addForm.all_members_can_post.selected,
        };
      } else {
        request_config = {
          action: "addDGList",
          token: localStorage.id_token,
          domain: selectedDomainForAdd,
          list: this.addForm.listName,
          dgtype: this.addForm.dgtype.selected,
          dg_protection: this.addForm.dg_protection.selected,
          send_to_sender: this.addForm.send_to_sender,
        };
      }

      postRequest(request_config).then((res) => {
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata+ '. ' + this.$t('COMMON.WAIT_MESSAGE'));
          this.getListsReqest(selectedDomainForAdd);
          this.modalClose("addList");
          this.resetForm();
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    /* ------create item end---- */
    /* -------delete item----- */
    saveDeltem(item) {
      this.delItem = item;
    },

    deleteRequest(item) {
      postRequest({
        action: "removeDGList",
        token: localStorage.id_token,
        list: item.name,
        domain: this.selectDomain.selectedDomain.domain,
      }).then((res) => {
        this.confirmModalClose("delConfirm-modal");
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata+ '. ' + this.$t('COMMON.WAIT_MESSAGE'));
          for (let i = 0; i < this.listTable.lists.length; i++) {
            if (this.listTable.lists[i].name == item.name) {
              this.listTable.lists.splice(i, 1);
              break;
            }
          }
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    deleteOneItem() {
      this.deleteRequest(this.delItem);
    },
    deleteItems() {
      if (this.listTable.selected.length > 0) {
        this.listTable.selected.forEach((el) => {
          this.deleteRequest(el);
        });
      }
    },

    /* ------edit item----- */
    showItemInfor(item) {
      this.editTitle = this.$t("COMMON.UPDATE") + item.name;
      this.isEdit = true;
      this.editData = item;
      postRequest({
        action: "getDGListProperties",
        token: localStorage.id_token,
        list: item.name,
        domain: this.selectDomain.selectedDomain.domain,
      }).then((res) => {
        if (res.returncode) {
          this.$refs["addList"].show();
          this.addForm.listName = item.name;
          this.addForm.dgtype.selected = res.returndata.type;
          this.addForm.dg_protection.selected = res.returndata.dg_protection;
          this.addForm.send_to_sender = res.returndata.send_to_sender;
          if (res.returndata.dg_protection == 2) {
            this.addForm.password = res.returndata.password;
          }
          if (res.returndata.all_members_can_post != null) {
            this.addForm.all_members_can_post.selected =
              res.returndata.all_members_can_post;
          }
        }
      });
    },
    updateItem() {
      const selectedDomainForUpdate = this.selectDomain.selectedDomain.domain;
      let request_config = {};
      if (this.addForm.dg_protection.selected == 2) {
        request_config = {
          action: "updateDGList",
          token: localStorage.id_token,
          listid: this.editData.id,
          list: this.addForm.listName,
          dgtype: this.addForm.dgtype.selected,
          password: this.addForm.password,
          send_to_sender: this.addForm.send_to_sender,
          dg_protection: this.addForm.dg_protection.selected,
        };
      } else if (
        this.addForm.dg_protection.selected == 1 &&
        this.addForm.dgtype.selected == 2
      ) {
        request_config = {
          action: "updateDGList",
          token: localStorage.id_token,
          listid: this.editData.id,
          list: this.addForm.listName,
          dgtype: this.addForm.dgtype.selected,
          send_to_sender: this.addForm.send_to_sender,
          dg_protection: this.addForm.dg_protection.selected,
          all_members_can_post: this.addForm.all_members_can_post.selected,
        };
      } else {
        request_config = {
          action: "updateDGList",
          token: localStorage.id_token,
          listid: this.editData.id,
          list: this.addForm.listName,
          dgtype: this.addForm.dgtype.selected,
          send_to_sender: this.addForm.send_to_sender,
          dg_protection: this.addForm.dg_protection.selected,
        };
      }
      postRequest(request_config).then((res) => {
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
          this.modalClose("addList");
          this.resetForm();
          this.getListsReqest(selectedDomainForUpdate);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },

    /* ------Member manage----- */
    showMembers(item) {
      this.memberManage.memberTitle =
        this.$t("SMARTLISTS.MEMBER_MANAGEMENT") + " (" + item.name + ")";
      this.editData = item;
      this.memberManage.memberEmail.data = [];
      postRequest({
        action: "getDGListMembers",
        token: localStorage.id_token,
        listid: item.id,
      }).then((res) => {
        if (res.returncode) {
          if (item.type == 2) {
            let updated_res = [];
            for (var k in res.returndata) {
              if (res.returndata.hasOwnProperty(k)) {
                updated_res.push(res.returndata[k]);
              }
            }
            res.returndata = updated_res;
          }

          for (let i = 0; i < res.returndata.length; i++) {
            if (res.returndata[i].can_post == 0) {
              res.returndata[i].can_post = this.$t("COMMON.NO");
            } else {
              res.returndata[i].can_post = this.$t("COMMON.YES");
            }
            if (res.returndata[i].will_receive == 0) {
              res.returndata[i].will_receive = this.$t("COMMON.NO");
            } else {
              res.returndata[i].will_receive = this.$t("COMMON.YES");
            }
          }
          this.memberManage.members = res.returndata;
          postRequest({
            action: "getAllUsersDomain",
            token: localStorage.id_token,
            domain: this.selectDomain.selectedDomain.domain,
            offset: 0,
            limit: 0,
            sorting: 0,
            slicing: 0,
            getOTPAndToken: 0,
            showQuota: 0,
          }).then((res) => {
            if (res.returncode) {
              this.memberManage.memberEmail.selected = res.returndata[0].email;
              res.returndata.forEach((el) => {
                this.memberManage.memberEmail.data.push({
                  value: el.email,
                  text: el.email,
                });
              });
            } else {
              this.memberManage.memberMode.selected = 1;
            }
            this.memberManage.memberMode.selected = 0;
            this.$refs["memberManage"].show();
          });
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    addMember() {
      let memberName = this.addForm.memberName;
      if (this.memberManage.memberMode.selected) {
        this.$v.addForm.$touch();
        if (this.$v.addForm.memberName.$anyError) {
          return;
        }
      } else {
        memberName = this.memberManage.memberEmail.selected;
      }

      const lid = this.editData.id;
      postRequest({
        action: "addDGListMember",
        token: localStorage.id_token,
        listid: lid,
        member: memberName,
        can_post: this.memberManage.can_post.selected,
        will_receive: this.memberManage.can_receive.selected,
      }).then((res) => {
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
          postRequest({
            action: "getDGListMembers",
            token: localStorage.id_token,
            listid: lid,
          }).then((res) => {
            if (res.returncode) {
              if (this.editData.type == 2) {
                let updated_res = [];
                for (var k in res.returndata) {
                  if (res.returndata.hasOwnProperty(k)) {
                    updated_res.push(res.returndata[k]);
                  }
                }
                res.returndata = updated_res;
              }
              for (let i = 0; i < res.returndata.length; i++) {
                if (res.returndata[i].can_post == 0) {
                  res.returndata[i].can_post = this.$t("COMMON.NO");
                } else {
                  res.returndata[i].can_post = this.$t("COMMON.YES");
                }
                if (res.returndata[i].will_receive == 0) {
                  res.returndata[i].will_receive = this.$t("COMMON.NO");
                } else {
                  res.returndata[i].will_receive = this.$t("COMMON.YES");
                }
              }
              this.memberManage.members = res.returndata;
              this.addForm.memberName = "";
              this.$v.$reset();
            } else {
              this.memberManage.members = [];
            }
          });
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    saveDelOneMember(item) {
      this.memberManage.delMemberName = item.member;
    },
    deleteMemberRequest(member) {
      postRequest({
        action: "removeDGListMember",
        token: localStorage.id_token,
        listid: this.editData.id,
        member: member,
      }).then((res) => {
        this.confirmModalClose("delMemberConfirm-modal");
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
          // for (let i = 0; i < this.memberManage.members.length; i++) {
          //   if (this.memberManage.members[i].member == member) {
          //     this.memberManage.members.splice(i, 1);
          //     break;
          //   }
          // }
          postRequest({
            action: "getDGListMembers",
            token: localStorage.id_token,
            listid: this.editData.id,
          }).then((res) => {
            this.memberManage.selected = [];
            if (res.returncode) {
              if (this.editData.type == 2) {
                let updated_res = [];
                for (var k in res.returndata) {
                  if (res.returndata.hasOwnProperty(k)) {
                    updated_res.push(res.returndata[k]);
                  }
                }
                res.returndata = updated_res;
              }
              for (let i = 0; i < res.returndata.length; i++) {
                if (res.returndata[i].can_post == 0) {
                  res.returndata[i].can_post = this.$t("COMMON.NO");
                } else {
                  res.returndata[i].can_post = this.$t("COMMON.YES");
                }
                if (res.returndata[i].will_receive == 0) {
                  res.returndata[i].will_receive = this.$t("COMMON.NO");
                } else {
                  res.returndata[i].will_receive = this.$t("COMMON.YES");
                }
              }
              this.memberManage.members = res.returndata;
              this.addForm.memberName = "";
              this.$v.$reset();
            } else {
              this.memberManage.members = [];
            }
          });
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    deleteOneMember() {
      const member = this.memberManage.delMemberName;
      this.deleteMemberRequest(member);
    },
    deleteMembers() {
      if (this.memberManage.selected.length > 0) {
        this.memberManage.selected.forEach((el) => {
          this.deleteMemberRequest(el.member);
        });
      }
    },

    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
     /* ---- change domain dropdown ---- */
    onChangeDomain(newValue) {
      this.getListsForSelectedDomain(newValue.domain);
    },
  },
};
</script>
